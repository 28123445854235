.logo {
  margin-bottom: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.formContainer {
  width: 80%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.formContainer input {
  text-indent: 10px;
}

.form-item {
  display: flex;
  flex-direction: column;
  height: 100px;
}

.label {
  height: 22px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #292929;
}

.input {
  width: 100%;
  height: 45px;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 7px;
}

.about {
  width: 100%;
  height: 400rem;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 7px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  padding-left: 10px;
}

.checkbox {
  border: 1px solid #707070;
  margin: 0px 3px 0px 0px;
}

.checkboxlabel {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #707070;
  margin-bottom: 3px;
}

.button {
  background: #243466;
  border-radius: 5px;
  height: 45px;
  color: white;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  margin-left: 0px;
}

.buttonload {
  background: #243466;
  border-radius: 5px;
  height: 45px;
  color: white;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.loading {
  margin-left: -12px;
  margin-right: 8px;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 410px;
  text-align: center;
  border-radius: 5px;
}

.offscreen {
  position: absolute;
  left: -9999px;
}
