.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-editor {
  border-radius: 5px;
  border: 1px solid grey;
}

button.is-active {
    background: rgb(197, 197, 197);
    padding: 2px 3px;
    border-radius: 2px;
}

.ProseMirror {
    padding: 10px;
    border-top: 1px solid grey;
    background: white;
    border-radius: 0 0 5px 5px;
    min-height: 10em;
}

.ProseMirror > * + * {
      margin-top: 0.75em;
}
  
.ProseMirror ul,
.ProseMirror ol {
      padding: 0 1rem;
}
  
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
      line-height: 1.1;
}
  
.ProseMirror code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
}
  
.ProseMirror pre {
      background: #0D0D0D;
      color: #FFF;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
}
.ProseMirror pre code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
}
  
.ProseMirror img {
      max-width: 100%;
      height: auto;
}
  
.ProseMirror blockquote {
      padding-left: 1rem;
      border-left: 2px solid #0D0D0D;
}
  
.ProseMirror hr {
      border: none;
      border-top: 2px solid #0D0D0D;
      margin: 2rem 0;
}
