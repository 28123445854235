.container {
  padding-bottom: 40px;
}

.form_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  /* background-color: red; */
  padding: 5px 30px;
}
.preview_content {
  display: flex;
  flex-direction: column;
}

.preview_content textarea {
  padding-top: 10px;
  height: 100px;
}

.tags {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.body {
  height: 100%;
  width: 100%;
}

.editor {
  background-color: whitesmoke;
  /* min-height: 200px; */
}

.submit_button {
  margin-top: 10px;
}
