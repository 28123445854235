.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-left: 50%;
  margin-top: 20%;
}
.spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #36539a;
  border-color: #36539a transparent #36539a transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 720px) {
  .spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-left: 40%;
    margin-top: 60%;
  }
  .spinner:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #36539a;
    border-color: #36539a transparent #36539a transparent;
    animation: spinner 1.2s linear infinite;
  }
}
