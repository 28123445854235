.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.form {
  width: 50%;
  margin: 100px 30px;
}

@media only screen and (max-width: 720px) {
  /* For mobile phones: */
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sidebar {
    visibility: hidden;
  }

  .form {
    width: 100%;
    margin: 40px 10px;
  }
}
